body {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("https://i.pinimg.com/originals/4c/a3/1e/4ca31e5343e89bc550044caec29543e6.jpg");   */
  background-color: #293942;
  background-size: cover;
  font-family: 'Comfortaa', sans-serif;
  color: lightcyan;
  overflow-x: hidden;
}



/* button:hover {
  background-color: gray;
  animation: boop 0.2s ease-in-out;
}

button:active {
  background-color: rgb(156, 156, 156);
  transition: transform 0.1s ease-in-out;
  transform : translate(2px, 2px);
}

@keyframes boop {
  0% {
    scale : 1;
  }
  50% {
    scale : 1.05;
  }
  100% {
    scale : 1;
  }
} */

