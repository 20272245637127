#plan {
    width: auto;
    height: auto;
}

.webstore_description, .webstore_title{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.webstore_container{
    background : #d9d9d9;
    margin : 20px auto;
    border-radius: 10px;
    box-shadow: inset 4px 4px 2px #9c9c9c;
}