.about-header {
  color: lightcyan;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

img {
  width: 50px;
  height: auto;
  transition: 0.2s;
}

img:hover {
  transform: scale(1.25);
  transition: 0.2s;
}

.about{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/aboutBG.png");
  background-position: 0px -35px; 
  background-repeat: no-repeat;
  background-size: cover;
}  

.about-container {
  height: 92.8vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  animation: fadeIn 0.5s ease-in forwards;
}

.tech-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1.2s ease-in forwards;
}

.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.highlight {
  color: #FFBD59;
}

#link-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation : fadeIn 1s ease-in forwards;
}

#hey {
  font-weight: lighter;
  font-size: 2.5em;
}

#github-inline{
  text-decoration: none;
  color: #FFBD59;
  transition: 0.2s;
}

#linkedin-github{
  text-decoration: none;
  color: #FFBD59;
  transition: 0.2s;
  font-weight: bold;
}

#linkedin-github:hover{
  transition: 0.2s;
  font-size: larger;
}

.my-name{
  font-weight: bold;
  color: #FFBD59;
}

@keyframes chromAbb {
  0%
  {text-shadow: 0 0 #a50610, 0 0 #0c2aa8, 0 6px 0 transparent, 8px 0 0 transparent}
  100%
  {text-shadow: 0.06ex 0 #a50610, -0.06ex 0 #0c2aa8, 0 0.14ex 0 rgba(0,0,0,.8), 0.12ex 0 0 rgba(0,0,0,.8) }
}
  
  
@keyframes fadeIn {
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
  filter: blur(0px);
  }
}
  
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .about{
    background-image: none;
  }

  .about-container {
    height: 100vh;
    margin: 1em;
  }
  .tech-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .tech-item {
    margin: 0.5em;
  }
  #hey {
    font-size: 2.5em;
  }


}