.nav-bar{
    width: 100vw;
    display: flex;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.95);
    user-select: none;
    
}

.nav-bar-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    color: lightcyan;
    z-index: 9999;
  }
  
.nav-bar-list-button {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 14px 16px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
  }
  
  .nav-bar-list-button:hover {
    color: #FFBD59;
    background-color: #2c2c2c;
    transition: 0.3s ease-in-out;
  }

  .projects-list {
    display: flex;
    flex-direction: column;
  }

  .drop-down{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }