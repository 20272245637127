.crt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
    margin-top: 6vh;
}

  @keyframes fadeIn {
    from {
      opacity: 0;
      filter: blur(5px);
    }
    to {
      opacity: 1;
    filter: blur(0px);
    }
  }

  @media (max-width: 768px) {

  }