.switch-outer{
    width: 300px;
    height: 150px;
    background:  #d9d9d9;
    border-radius: 5px;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.55), inset -2px -2px 3px rgba(0, 0, 0, 0.55);
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch-inner{
    width: 80%;
    height: 65%;
    background:  #b8b8b8;
    border-radius: 3px;
    box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.55);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.switch-inner[data-isOn="true"] {
    justify-content: flex-end;
  }

.switch-handle{
    width: 50%;
    height: 90%;
    margin: 4px;
    background: #d9d9d9;
    color: #b8b8b8;
    font-size: 70px;
    text-shadow: inset 2px 2px 4px 2px rgba(0, 0, 0, 0.55);
    letter-spacing: -9px;
    text-align: left;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.55), inset 2px 2px 5px 2px rgba(0, 0, 0, 0.15), inset -2px -2px 5px 2px rgba(0, 0, 0, 0.15);
}