/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&family=Rubik:wght@400;700&display=swap');

.littr {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.littr-container {
    width: 80%;
    max-width: 1400px;
    animation: fadeIn 0.5s ease-in forwards;
    box-sizing: border-box;
}

.littr-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: left;
}

.littr-tech-item img {
    width: 16px;
    height: auto;
    transition: 0.2s;
}

.littr-title,
.littr-subtitle,
.littr-link {
    font-family: 'Montserrat', sans-serif;
    color: #FFBD59;
}

.littr-title {
    font-size: larger;
    text-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.littr-description {
    word-wrap: break-word;
    width: fit-content;
}

.littr-video{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.littr-video-frame {
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

.littr-link {
    transition: 0.2s;
    color: #FFBD59;
}

.littr-link:hover {
    color: lightcyan;
}

.example-images-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.example-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-description {
    font-size: 12px;
    text-align: center;
}

#example-button{
    background-color: #2F3E46;
    color: #D9D9D9;
    box-shadow: 4px 4px 1px 0px rgba(0, 0, 0, 0.55);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    font-weight: semi-bold;
    width: 200px;
    height: 45px;
    transition: 0.2s;
}

#example-button:hover{
    background-color: #D9D9D9;
    color: #2F3E46;
    border-radius: 5px;
    font-size: 12px;
    font-weight: semi-bold;
}

#example-button:active{
    background-color: #D9D9D9;
    color: #2F3E46;
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.55);
    transition: 0.4s;
}

#bad,
#good,
#card,
#moodboard,
#fella {
    width: auto;
    max-width: 500px;
    max-height: 200px;
    height: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        filter: blur(5px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .littr-container {
        max-width: 380px;

    }
    .littr-list-container {
        justify-content: center;
        flex-direction: column;
    }

    .example-images-container {
        flex-direction: column;
        align-items: center;
    }

    #bad,
    #good,
    #card,
    #moodboard {
    width: 100%;
    max-height: 100px;
    }

    #fella {
        width: auto;
        max-height: 100px;
    }

    .littr-description {
        font-size: smaller;
    }
    .littr-video-frame {
        width: 100%
    }
}
