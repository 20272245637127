.card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    width: 97%;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }