.plastic-casing-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 700px;
    height: 700px;
    background-color: bisque;
    border-radius: 25px;
    box-shadow: inset -3px -3px 4px 8px #0000001f, 18px 18px 10px 10px #0000001f, 5px 2px blanchedalmond, 8px 3px blanchedalmond, 9px 4px blanchedalmond, 10px 5px blanchedalmond, 11px 6px blanchedalmond, 12px 7px blanchedalmond, 13px 8px bisque, 14px 9px bisque, 15px 10px bisque;
    border: none;
    padding-bottom: 60px;
    
}

.plastic-casing-inner{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 580px;
    height: 580px;
    background-color: #282c34;
    border-radius: 10px;
    border: none;
    box-shadow: -1px -1px 4px 6px #0000001f;
}

.button-container{
    transform: translateY(350px);
    width: 80%;
    height: 60px;
    background-color: rgb(245, 218, 184);
    border-radius: 4px;
    box-shadow: inset 2px 2px 3px 3px #0000001f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.control-button{
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 4px;
    background-color: bisque;
    font-size: 22px;
    font-weight: bolder;
    text-shadow:  1px 1px 0px #0000005e, 1.5px 1.5px 1px #0000005e; 
    color: rgb(187, 167, 143);
    box-shadow: 2px 2px 0.5px 1px #0000001f;
}

.control-button:active{
    transform: translate(1.5px, 1.5px);
    box-shadow: 0.5px 0.5px 0.5px 1px #0000001f;
}