.pong_ball {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
    transition: all 0.2s ease;
}

.pong_paddle {
  position: absolute;
  width: 10px;
  height: 60px;
  background-color: #fff;
  transition: all 0.2s ease;
}

.pong_controls {
    position: absolute;
    top: 200px;
    left: -20px;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pong_score {
    display: flex;
    flex-direction: row;    
    justify-content: center;
    align-items: center;
}




