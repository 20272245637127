.interface{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.interface-title{
    color: #FFBD59;

}

.interface-subtitle{
    color: #FFBD59;
    margin: 10px;
}

.interface-description{
    margin: 10px;
}

.interface-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#tiny-text{
    font-size: 10px;
}
