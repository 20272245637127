@keyframes flicker {
  0% {
    opacity: 0.28;
  }
  5% {
    opacity: 0.35;
  }
  10% {
    opacity: 0.24;
  }
  15% {
    opacity: 0.91;
  }
  20% {
    opacity: 0.18;
  }
  25% {
    opacity: 0.84;
  }
  30% {
    opacity: 0.66;
  }
  35% {
    opacity: 0.68;
  }
  40% {
    opacity: 0.27;
  }
  45% {
    opacity: 0.85;
  }
  50% {
    opacity: 0.96;
  }
  55% {
    opacity: 0.09;
  }
  60% {
    opacity: 0.20;
  }
  65% {
    opacity: 0.72;
  }
  70% {
    opacity: 0.53;
  }
  75% {
    opacity: 0.37;
  }
  80% {
    opacity: 0.71;
  }
  85% {
    opacity: 0.70;
  }
  90% {
    opacity: 0.70;
  }
  95% {
    opacity: 0.36;
  }
  100% {
    opacity: 0.24;
  }
}


@keyframes textShadow {
  0% {
    text-shadow: 0.38px 0 1px rgba(0, 30, 255, 0.5), -0.38px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.69px 0 1px rgba(0, 30, 255, 0.5), -2.69px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02px 0 1px rgba(0, 30, 255, 0.5), -0.02px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.32px 0 1px rgba(0, 30, 255, 0.5), -0.32px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.25px 0 1px rgba(0, 30, 255, 0.5), -3.25px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.51px 0 1px rgba(0, 30, 255, 0.5), -1.51px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.6px 0 1px rgba(0, 30, 255, 0.5), -0.6px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.75px 0 1px rgba(0, 30, 255, 0.5), -3.75px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.73px 0 1px rgba(0, 30, 255, 0.5), -3.73px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.09px 0 1px rgba(0, 30, 255, 0.5), -2.09px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.1px 0 1px rgba(0, 30, 255, 0.5), -0.1px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.22px 0 1px rgba(0, 30, 255, 0.5), -2.22px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.05px 0 1px rgba(0, 30, 255, 0.5), -2.05px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.71px 0 1px rgba(0, 30, 255, 0.5), -2.71px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.42px 0 1px rgba(0, 30, 255, 0.5), -0.42px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.82px 0 1px rgba(0, 30, 255, 0.5), -1.82px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.1px 0 1px rgba(0, 30, 255, 0.5), -0.1px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.12px 0 1px rgba(0, 30, 255, 0.5), -0.12px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.34px 0 1px rgba(0, 30, 255, 0.5), -3.34px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.08px 0 1px rgba(0, 30, 255, 0.5), -2.08px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.52px 0 1px rgba(0, 30, 255, 0.5), -2.52px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
}

  @keyframes leftToRight {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-6deg);
    }
    45% {
      transform: rotate(-6deg); 
    }
    50% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg); 
    }
    75% {
      transform: rotate(6deg);
    }
    95% {
      transform: rotate(6deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes hueRotate {
    0% {
      filter: hue-rotate(0deg);
    }
    25% {
      filter: hue-rotate(90deg);
    }
    50% {
      filter: hue-rotate(180deg);
    }
    75% {
      filter: hue-rotate(270deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

  .crtScreen::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(72, 88, 77, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: flicker 0.05s infinite;
    border-radius: 10px;
    box-shadow: inset 0 0 10px 15px rgba(0, 0, 0, 0.164);
  }
  .crtScreen::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px 25px rgba(0, 0, 0, 0.164), inset 0px 0px 3px 4px rgba(255, 255, 255, 0.450);
  }
  .crtScreen {
    animation: textShadow 0.05s infinite;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: beige;
  }

  .crtScreen-off {
    filter: blur(3px);
    transition: all 0.3s;
    animation: textShadow 0.05s infinite;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: beige;
    opacity: 0;
  }

  .crtScreen-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "VT323", monospace;
    animation: hueRotate 0.05s linear infinite;
    user-select: none;
  }
  
  #hire-now{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .crtScreen-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #my-face {
    width: 100px;
    animation: leftToRight 1s linear infinite;
  }

  #hire-me-button {
    border: none;
    border-radius: 5px;
    font-weight: bold;
  }

  #screen-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }

