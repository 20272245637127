.nav-drop-down {
    width: 100%;
    margin-top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-decoration: none;
    color: lightcyan;
    z-index: 9000;
    position: absolute;
}

.drop-down {
    height : 100%;
    width: 33vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2c;
    border-radius: 0px 0px 10px 10px;
    user-select: none;
    text-decoration: none;
    color: lightcyan;
    margin-top: 70px;
    z-index: 9000;
}

.drop-down-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c2c2c;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    color: lightcyan;
    z-index: 9000;
}

.drop-down-spacer{
height: 40px;
}

.drop-down-item:hover{
    background-color: #333;
    transition: 0.2s;
}

.drop-down-item:active{
    background-color: #333;
    transition: 0.1s;
    width: 80%;
    border-radius: 5px 5px 5px 5px;
    color: #FFBD59;
}